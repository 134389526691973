import * as Sentry from '@sentry/vue'
import { debounce, throttle, noop } from '@zoomcatalog/shared'
import compressor from 'pako'
import { useNotification } from '@/composable/useNotification'
import { rootStore } from '@/store/rootStore'
import { queueMutator } from './queueModifiers'
import {
	ADD_PAGE,
	AutoSaveOptions,
	BaseCommand,
	CLONE_PAGE,
	Command,
	DELETE_PAGE,
	isMultiEventsCommand,
	KeepAliveCommand,
	SocketMessage,
	UPDATE_PAGE,
	UPDATE_PAGES_INDEXES,
	WebSocketPayload,
} from './types'

export class AutoSave {
	#socketUrl: string
	#socket!: WebSocket
	#loggerEnabled: boolean

	#sendingMessage = false
	#canSendMessages = false
	isSending = false
	isServerLocked = false
	#canEnqueueMessages = false
	hasServerConfirmation = false
	#messageId = 0

	#lowestSizeToCompress = 32_768
	#retryUntilMs = 500
	#debounceQueueMs = 2_000
	autoSaveInterval: any = null
	lastExpectedCommand: Command | null = null
	lastCommandSent: Command | null = null
	outgoingActions: Array<string> = []
	commandsHistory: Array<Command> = []
	socketErrors = ['locked', 'not found', 'Invalid action', 'Duplicate']
	paginationCommands: Command['action'][] = [
		ADD_PAGE,
		DELETE_PAGE,
		CLONE_PAGE,
		UPDATE_PAGE,
		UPDATE_PAGES_INDEXES,
	]
	ignoredWSCodes = [1000, 1001]

	constructor(options: AutoSaveOptions) {
		this.#loggerEnabled = options.enableLogger ?? true

		this.#socketUrl = options.socketUrl
		this.makeSocketConnection()
	}

	getBaseCommand(): BaseCommand {
		this.#messageId += 1
		const userToken = localStorage.getItem('userToken')

		return {
			endpoint: 'send_changes',
			resource: 'flyer',
			timestamp: Date.now(),
			id: this.#messageId,
			token: userToken || '',
		}
	}

	getSocket() {
		return this.#socket
	}

	hasConnection() {
		return this.#socket.readyState === this.#socket.OPEN && navigator.onLine
	}

	setSendingMessage(value: boolean) {
		this.#sendingMessage = value
	}

	setCanSendMessages(value: boolean) {
		this.#canSendMessages = value
	}

	setCanEnqueueMessages(value: boolean) {
		this.#canEnqueueMessages = value
	}

	setIsServerLocked(value: boolean) {
		this.isServerLocked = value
	}

	makeSocketConnection() {
		if (this.#socket && this.#socket.readyState !== this.#socket.CLOSED) {
			return
		}

		this.makeNewConnection()
	}

	makeNewConnection() {
		this.#socket?.close()
		this.#socket = new WebSocket(this.#socketUrl)
		this.setupEvents()
		this.hasServerConfirmation = false
	}

	isSocketSuccess(message: SocketMessage) {
		return (
			(Array.isArray(message) && message.every((msg) => msg.code === 200)) ||
			(typeof message === 'object' &&
				(message as { code: number; message: string }).code === 200)
		)
	}

	setupEvents() {
		if (!this.autoSaveInterval) {
			this.autoSaveInterval = setInterval(() => {
				// Copy tempQueue to queue and clear tempQueue
				if (
					rootStore.state.autoSave.queue.length == 0 &&
					rootStore.state.autoSave.tempQueue.length > 0
				) {
					rootStore.state.autoSave.queue = rootStore.state.autoSave.tempQueue
					rootStore.state.autoSave.tempQueue = []
				}
				this.sendEvents(true)
			}, 1500)
		}

		this.#socket.onopen = () => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log('AutoSave socket connected')
			}
		}

		this.#socket.onclose = ($event) => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log('AutoSave socket disconnected')
			}

			if (!this.ignoredWSCodes.includes($event.code) && !navigator.onLine) {
				Sentry.captureEvent({
					message: `${$event.code}: Websocket was closed because there's no internet connection`,
					level: Sentry.Severity.Warning,
				})
			}
		}

		this.#socket.onmessage = (event: { data: string }) => {
			const { data } = event
			const message = JSON.parse(data) as SocketMessage
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log(`AutoSave socket message: `, message)
			}

			const lastAction = this.outgoingActions.shift()
			if (lastAction === 'keep-alive') return (this.hasServerConfirmation = true)
			if (
				((this.lastExpectedCommand?.action === this.lastCommandSent?.action &&
					!this.isSending) ||
					(!this.lastExpectedCommand && !this.isSending)) &&
				rootStore.state.autoSave.tempQueue.length === 0 &&
				rootStore.state.autoSave.queue.length === 0
			) {
				rootStore.commit({ type: 'autoSave/setIsSending', payload: false })
			}

			if (!this.isSocketSuccess(message)) {
				this.setIsServerLocked(true)
				this.setCanSendMessages(false)
				console.log('socket error', message)

				if (this.lastCommandSent) {
					if (typeof message === 'object') {
						for (
							let i = 0;
							i < (message as Array<{ code: number; message: string }>).length;
							i++
						) {
							if (
								(message as Array<{ code: number; message: string }>)[i].code === 428
							) {
								this.#messageId += 1
								this.lastCommandSent.id = this.#messageId
							}
						}
					}
					rootStore.state.autoSave.tempQueue.push(this.lastCommandSent)
				}

				Sentry.captureEvent({
					message: `${data.toString()} - Websocket`,
					level: Sentry.Severity.Error,
					extra: {
						commandsHistory: this.commandsHistory,
						lastCommandSent: this.lastCommandSent,
						lastExpectedCommand: this.lastExpectedCommand,
						canSendMessages: this.#canSendMessages,
						isSending: this.isSending,
						isServerLocked: this.isServerLocked,
						canEnqueueMessages: this.#canEnqueueMessages,
						hasServerConfirmation: this.hasServerConfirmation,
						messageId: this.#messageId,
						lastReceivedMessage: message,
					},
				})
			} else {
				this.setIsServerLocked(false)
				this.setCanSendMessages(true)
			}
			this.setSendingMessage(false)
		}

		this.#socket.onerror = (event) => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.error(`AutoSave socket error: `, event)
			}

			if (this.lastCommandSent) {
				rootStore.state.autoSave.tempQueue.push(this.lastCommandSent)
			}

			Sentry.captureEvent({
				message: `There was an error with the websocket.`,
				level: Sentry.Severity.Error,
				extra: {
					commandsHistory: this.commandsHistory,
					lastCommandSent: this.lastCommandSent,
					lastExpectedCommand: this.lastExpectedCommand,
					canSendMessages: this.#canSendMessages,
					isSending: this.isSending,
					isServerLocked: this.isServerLocked,
					canEnqueueMessages: this.#canEnqueueMessages,
					hasServerConfirmation: this.hasServerConfirmation,
					messageId: this.#messageId,
				},
			})
			this.showNotification()
			this.setSendingMessage(false)
		}
	}

	showNotification = throttle(() => {
		const { enqueueNotification } = useNotification()
		enqueueNotification({
			message:
				"We were not able to save this design. We'll keep retrying. Refreshing might lose your work.",
			color: 'danger',
		})
	}, 10000)

	debounceQueue = debounce(async (shouldMutateQueue: boolean) => {
		this.sendEvents(shouldMutateQueue)
	}, this.#debounceQueueMs / 2)

	enqueue<T extends Command>(
		command: Omit<T, 'resource' | 'endpoint' | 'id' | 'token' | 'timestamp'>,
		options = { shouldMutate: true }
	) {
		if (!this.#canEnqueueMessages) return
		const newCommand = {
			...this.getBaseCommand(),
			...command,
		} as unknown as Command

		rootStore.commit({
			type: 'autoSave/enqueueCommand',
			payload: newCommand,
		})
		this.commandsHistory.push(newCommand)
		// rootStore.commit({ type: 'autoSave/setIsSending', payload: true })
		this.debounceQueue(options.shouldMutate)
	}

	async sendEvents(shouldMutateQueue: boolean) {
		// Evaluate if there is no other process running
		if (rootStore.state.autoSave.queue.length == 0 || this.isSending === true) {
			return
		}
		this.isSending = true
		await this.retryUntil(() => this.#canSendMessages, 25, 10)

		const differenceInMilliseconds =
			Date.now() - (this.lastCommandSent?.timestamp ?? 0)
		const differenceInSeconds = differenceInMilliseconds / 1000
		const shouldAskServerIfHasConnection = differenceInSeconds >= 30

		if (shouldAskServerIfHasConnection) {
			await this.retryUntil(async () => {
				await this.askServerIfHasConnection()

				return this.hasServerConfirmation
			})
		}

		const queueCommands = rootStore.state.autoSave.queue
		rootStore.state.autoSave.queue = []

		const mutatedQueue = shouldMutateQueue
			? queueMutator(queueCommands)
			: queueCommands

		this.lastExpectedCommand = mutatedQueue[mutatedQueue.length - 1]

		for (const command of mutatedQueue) {
			// Re order queue to mantain FIFO
			if (!this.#canSendMessages) {
				rootStore.state.autoSave.tempQueue.push(command)
				continue
			}

			// Delay between event
			setTimeout(() => {
				noop()
			}, 50)

			rootStore.commit({ type: 'autoSave/setIsSending', payload: true })
			this.lastCommandSent = command
			this.outgoingActions.push(command.action)

			this.setCanSendMessages(false)
			this.sendCommand(this.compressCommand(command, this.#lowestSizeToCompress))

			// Waiting until sending message it's done
			await this.retryUntil(() => !this.#sendingMessage, 75, 10)
		}

		this.isSending = false

		setTimeout(() => {
			this.setCanSendMessages(true)
		}, 1500)

		if (
			rootStore.state.autoSave.queue.length === 0 &&
			rootStore.state.autoSave.tempQueue.length === 0
		) {
			rootStore.commit({ type: 'autoSave/setIsSending', payload: false })
		}
	}

	async retryUntil(
		condition: () => Promise<boolean> | boolean,
		retryDelay = this.#retryUntilMs,
		maxRetries?: number // Optional limiter for retries
	): Promise<boolean> {
		let retries = 0

		// Continuously poll until the condition returns true or maxRetries is reached
		while (!(await condition())) {
			if (maxRetries !== undefined && retries >= maxRetries) {
				return false // Return false if the retry limit is reached
			}

			// Wait for the specified delay before trying again
			await new Promise((resolve) => setTimeout(resolve, retryDelay))

			retries++
		}

		return true // Return true when the condition becomes true
	}

	async askServerIfHasConnection() {
		this.hasServerConfirmation = false
		const keepAliveCommand: KeepAliveCommand = {
			...this.getBaseCommand(),
			action: 'keep-alive',
			data: {},
		}
		this.sendCommand(keepAliveCommand)
		this.outgoingActions.push(keepAliveCommand.action)

		await this.retryUntil(() => this.hasServerConfirmation, 500, 10)

		if (this.hasServerConfirmation) return

		this.outgoingActions.shift()
		this.makeNewConnection()
	}

	sendCommand(command: WebSocketPayload) {
		this.#sendingMessage = true
		this.#socket.send(JSON.stringify(command))
	}

	compressCommand(command: Command, lowestSize: number): WebSocketPayload {
		if (
			JSON.stringify(command).length < lowestSize ||
			isMultiEventsCommand(command)
		)
			return command

		const commandString = JSON.stringify(command.data)

		const unicodeList = new TextEncoder().encode(commandString)

		const gzippedList = compressor.gzip(unicodeList)

		const gzippedString = Array.from(gzippedList)
			.map((unicode) => String.fromCharCode(unicode))
			.join('')

		const b64Data = btoa(gzippedString)

		return { ...command, data: b64Data }
	}

	destructor() {
		if (this.autoSaveInterval) clearInterval(this.autoSaveInterval)
	}
}
