import { Page, Product } from '@/store/interfaces'

export const UPDATE_PAGES_INDEXES = 'update_pages_indexes'
export const ADD_PAGE = 'add_page'
export const UPDATE_PAGE = 'update_page'
export const DELETE_PAGE = 'delete_page'
export const ADD_PRODUCTS = 'add_products'
export const REMOVE_PRODUCT = 'remove_product'
export const ADD_LOGO = 'add_logo'
export const REMOVE_LOGO = 'remove_logo'
export const CLONE_PAGE = 'clone_page'
export const MULTI_EVENTS = 'multi_events'
export const UPDATE_PAGE_MEDIA = 'update_page_media'

export type AutoSaveOptions = {
	socketUrl: string
	enableLogger?: boolean
}
export type BaseCommand = {
	endpoint: 'send_changes'
	resource: 'flyer'
	timestamp: number
	id: number
	token: string | null
}
export type KeepAliveCommand = {
	action: 'keep-alive'
	data: Record<string, unknown>
} & BaseCommand
export type AddPageCommand = {
	action: typeof ADD_PAGE
	data: {
		project_id: string
		page_id: string
		layout_id: string
		index: number
		settings: Page['settings']
		cover_image: string
		title: string
	}
} & BaseCommand
export type UpdatePagesIndexesCommand = {
	action: typeof UPDATE_PAGES_INDEXES
	data: {
		project_id: string
		pages: Record<string, number>
	}
} & BaseCommand
export type UpdatePageCommand = {
	resource: 'flyer'
	action: typeof UPDATE_PAGE
	data: {
		project_id: string
		page_id: string
		layout_id: string
		index: number
		settings: Page['settings']
		cover_image: string
		title: string
	}
} & BaseCommand
export type DeletePageCommand = {
	action: typeof DELETE_PAGE
	data: {
		project_id: string
		page_id: string
		reindex: boolean
	}
} & BaseCommand
export type AddProductsCommand = {
	action: typeof ADD_PRODUCTS
	data: {
		project_id: string
		page_id: string
		products: Product[]
	}
} & BaseCommand
export type RemoveProductCommand = {
	action: typeof REMOVE_PRODUCT
	data: {
		project_id: string
		page_id: string
		slot: number
	}
} & BaseCommand
export type AddLogoCommand = {
	action: typeof ADD_LOGO
	data: {
		project_id: string
		page_id: string
		slot: number
		logo_id: string
		url: string
	}
} & BaseCommand
export type RemoveLogoCommand = {
	resource: 'flyer'
	action: typeof REMOVE_LOGO
	data: {
		project_id: string
		page_id: string
		slot: number
	}
} & BaseCommand
export type DuplicatePageCommand = {
	resource: 'flyer'
	action: typeof CLONE_PAGE
	data: {
		project_id: string
		source_page_id: string
		target_page_id: string
		spreadPage?: 'left' | 'right' | null
	}
} & BaseCommand
export type UpdatePageMediaCommand = {
	resource: 'flyer'
	action: typeof UPDATE_PAGE_MEDIA
	data: {
		project_id: string
		page_id: string
	}
} & BaseCommand
export type Command =
	| DuplicatePageCommand
	| KeepAliveCommand
	| AddPageCommand
	| UpdatePagesIndexesCommand
	| UpdatePageCommand
	| DeletePageCommand
	| AddProductsCommand
	| RemoveProductCommand
	| AddLogoCommand
	| RemoveLogoCommand
	| UpdatePageMediaCommand
	| MultiEventsCommand
export type CompressedWSPayload = Omit<Command, 'data'> & {
	data: string
}
export type WebSocketPayload = Command | CompressedWSPayload
export type SocketMessage =
	| string
	| Record<string, unknown>
	| Array<{ code: number; message: string }>
	| { code: number; message: string }
export type MultiEventsCommand = {
	action: typeof MULTI_EVENTS
	commands: Array<WebSocketPayload>
} & Omit<BaseCommand, 'resource'>

// Guards
export function isMultiEventsCommand(
	command: unknown
): command is MultiEventsCommand {
	return (
		typeof command === 'object' &&
		command !== null &&
		!('data' in command) &&
		'commands' in command
	)
}

export function isUpdatePageCommand(
	command: unknown
): command is UpdatePageCommand {
	return isActionCommand(command) && command.action === UPDATE_PAGE
}
export function isAddProductsCommand(
	command: unknown
): command is AddProductsCommand {
	return isActionCommand(command) && command.action === ADD_PRODUCTS
}
export function isRemoveProductCommand(
	command: unknown
): command is RemoveProductCommand {
	return isActionCommand(command) && command.action === REMOVE_PRODUCT
}
export function isAddPageCommand(command: unknown): command is AddPageCommand {
	return isActionCommand(command) && command.action === ADD_PAGE
}
export function isDeletePageCommand(
	command: unknown
): command is DeletePageCommand {
	return isActionCommand(command) && command.action === DELETE_PAGE
}
export function isClonePageCommand(
	command: unknown
): command is DuplicatePageCommand {
	return isActionCommand(command) && command.action === CLONE_PAGE
}
export function isUpdatePagesIndexesCommand(
	command: unknown
): command is UpdatePagesIndexesCommand {
	return isActionCommand(command) && command.action === UPDATE_PAGES_INDEXES
}
export function isUpdatePageMediaCommand(
	command: unknown
): command is UpdatePageMediaCommand {
	return isActionCommand(command) && command.action === UPDATE_PAGE_MEDIA
}
export function isCompressedCommand(
	command: unknown
): command is CompressedWSPayload {
	return (
		typeof command === 'object' &&
		command !== null &&
		hasProperty(command, 'data') &&
		typeof command.data === 'string'
	)
}
export function isActionCommand(
	command: unknown
): command is Exclude<Command, MultiEventsCommand> {
	return (
		typeof command === 'object' &&
		command !== null &&
		hasProperty(command, 'action') &&
		typeof command.action === 'string'
	)
}

const hasProperty = <Obj, Prop extends string>(
	obj: Obj,
	prop: Prop
): obj is Obj & Record<Prop, unknown> =>
	Object.prototype.hasOwnProperty.call(obj, prop)
